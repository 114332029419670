// src/js/stores/store.ts

import { defineStore } from 'pinia';
import axios from 'axios';
import { configureXhrApi, executeXhr } from '../utils/xhr.js';
import { configureGqlApi, executeGqlQuery } from '../utils/gql.js';
import { configureEventsApi, executeEventsQuery } from '../utils/events.js';
import { 
  ACTIVITIES_QUERY, 
  CLOSURE_QUERY, 
  EVENT_DETAIL, 
  HERO_SLIDER_QUERY, 
  NAVIGATION_QUERY, 
  NEWS_QUERY, 
  NEWS_RELATED_QUERY, 
  NEWS_CATEGORIES_QUERY, 
  PLACEHOLDERS_QUERY, 
  STATUSES_QUERY 
} from '../data/queries.js';
import { initializeGetters } from './getters.js';

// Define interfaces for tokens and CSRF to inform TypeScript of expected properties.
interface GqlToken {
  token: string;
}

interface CSRF {
  name: string;
  value: string;
}

// Define an interface for navigation items to ensure proper typing.
interface NavigationItem {
  id: string | number;
  children?: NavigationItem[]; // children is optional.
  typeHandle?: string;
  ageCategory?: { id: string | number }[];
}

export type MainStoreState = {
  activeChild: null | string;
  closures: null | object[];
  closure: null | object;
  csrf: CSRF | null;
  events: null | object[];
  eventDetail: null | object;
  featuredData: Record<string, unknown>;
  featuredStatuses: null | object[];
  filters: {
    news: string[];
  };
  placeholders: null | object;
  gqlToken: GqlToken | null;
  navigation: null | NavigationItem[]; // Updated type to use NavigationItem.
  news: {
    all: null | object[];
    related: null | object[];
  };
  newsCategories: null | object[];
  menuOpen: boolean;
  searchForm: {
    locations: string[];
    ages: string[];
    activities: string[];
    news: string[];
  };
  selectedCategories: {
    news: string[];
  };
  slides: null | object;
  statuses: null | object[];
  slideouts: null | object[];
  version: string;
};

export const useMainStore = defineStore('mainStore', {
  state: (): MainStoreState => ({
    activeChild: null,
    closures: null,
    closure: null,
    csrf: null,
    events: null,
    eventDetail: null,
    featuredData: {},
    featuredStatuses: null,
    filters: {
      news: ['Adult Activities'],
    },
    placeholders: null,
    gqlToken: null,
    navigation: null,
    news: {
      all: null,
      related: null,
    },
    newsCategories: null,
    menuOpen: false,
    searchForm: {
      locations: [],
      ages: [],
      activities: [],
      news: [],
    },
    selectedCategories: {
      news: ['47100'], // default selected news category
    },
    slides: null,
    statuses: null,
    slideouts: null,
    version: '2.0.1',
  }),
  getters: initializeGetters(),
  actions: {
    // --- Helper methods to DRY up axios API configuration ---
    getXhrApi(endpoint: string) {
      return axios.create(configureXhrApi(endpoint));
    },
    getGqlApi(endpoint: string) {
      const token = this.gqlToken ? this.gqlToken.token : null;
      return axios.create(configureGqlApi(endpoint, token));
    },
    getEventsApi(endpoint: string) {
      return axios.create(configureEventsApi(endpoint));
    },

    // --- Refactored actions with debugging ---
    async fetchCsrf() {
      
        console.log('[Store] fetchCsrf called, current csrf:', this.csrf);
      
      const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
      const api = this.getXhrApi(CSRF_ENDPOINT);
      const variables = {};
      try {
        await executeXhr(api, variables, (data: any) => {
          
            console.log('[Store] fetchCsrf response data:', data);
          
          this.csrf = data;
        });
        
          console.log('[Store] fetchCsrf success, updated csrf:', this.csrf);
        
      } catch (error) {
        console.error('Failed to fetch CSRF token:', error);
        throw error;
      }
    },
    async fetchGqlToken() {
      
        console.log('[Store] fetchGqlToken called with csrf:', this.csrf);
      
      const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
      const api = this.getXhrApi(TOKEN_ENDPOINT);
      const variables = {
        ...(this.csrf && { [this.csrf.name]: this.csrf.value }),
      };
      await executeXhr(api, variables, (data: any) => {
        
          console.log('[Store] fetchGqlToken response data:', data);
        
        this.gqlToken = data;
      });
    },
    async fetchHeroSlides(options: any) {
      
        console.log('[Store] fetchHeroSlides called with options:', options);
      
      const api = this.getGqlApi('/api');
      const variables = options;
      await executeGqlQuery(api, HERO_SLIDER_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchHeroSlides response data:', data);
        
        if (data.entry && data.entry.heroBuilder) {
          // Merge new slides with existing ones
          this.slides = {
            ...this.slides,
            [options.entryId]: data.entry.heroBuilder[0].sliders,
          };
          
            console.log('[Store] Updated slides:', this.slides);
          
        }
      });
    },
    async fetchNavigation() {
      
        console.log('[Store] fetchNavigation called');
      
      const api = this.getGqlApi('/api');
      const variables = {};
      await executeGqlQuery(api, NAVIGATION_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchNavigation response data:', data);
        
        if (data.entries) {
          this.navigation = data.entries;
        }
      });
      // Process navigation children with type 'activities'
      if (this.navigation) {
        for (const item of this.navigation) {
          
            console.log('[Store] Processing navigation item:', item);
          
          if (item.children) {
            for (const child of item.children) {
              if (child.typeHandle === 'activities' && child.ageCategory && child.ageCategory.length > 0) {
                
                  console.log(`[Store] Fetching activities for child id ${child.id} with age ${child.ageCategory[0].id}`);
                
                await this.fetchActivities({ id: child.id, age: child.ageCategory[0].id });
              }
            }
          }
        }
      }
    },
    async fetchNews() {
      
        console.log('[Store] fetchNews called');
      
      const api = this.getGqlApi('/api');
      const variables = {};
      await executeGqlQuery(api, NEWS_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchNews response data:', data);
        
        if (data.entries) {
          this.news.all = data.entries;
        }
      });
    },
    async fetchNewsCategories() {
      
        console.log('[Store] fetchNewsCategories called');
      
      const api = this.getGqlApi('/api');
      const variables = {};
      await executeGqlQuery(api, NEWS_CATEGORIES_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchNewsCategories response data:', data);
        
        if (data.categories) {
          this.newsCategories = data.categories;
        }
      });
    },
    async fetchNewsRelated(options: any) {
      
        console.log('[Store] fetchNewsRelated called with options:', options);
      
      const api = this.getGqlApi('/api');
      const variables = { id: options ? options.id : null };
      await executeGqlQuery(api, NEWS_RELATED_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchNewsRelated response data:', data);
        
        if (data.entries) {
          this.news.related = data.entries;
        }
      });
    },
    async setNavigationState(options: any) {
      
        console.log('[Store] setNavigationState called with options:', options);
      
      if (options.level === 2 && this.navigation) {
        this.navigation = this.navigation.map((item: any) => ({
          ...item,
          active: item.id === options.id ? options.active : false,
        }));
      }
      if (options.level === 3 && this.navigation) {
        this.navigation = this.navigation.map((item: any) => ({
          ...item,
          children: item.children
            ? item.children.map((child: any) => ({
                ...child,
                active: child.id === options.id ? options.active : false,
              }))
            : null,
        }));
      }
      
        console.log('[Store] Navigation state updated:', this.navigation);
      
    },
    async fetchPlaceholders() {
      
        console.log('[Store] fetchPlaceholders called');
      
      const api = this.getGqlApi('/api');
      const variables = {};
      await executeGqlQuery(api, PLACEHOLDERS_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchPlaceholders response data:', data);
        
        if (data.globalSet) {
          this.placeholders = data.globalSet;
        }
      });
    },
    async fetchStatuses(options: any) {
      
        console.log('[Store] fetchStatuses called with options:', options);
      
      const api = this.getGqlApi('/api');
      const variables = {
        id: options.id ? options.id : null,
        type: options.type,
        featured: options.featured ? true : null,
        parks: options.parks ? options.parks : null,
      };
      await executeGqlQuery(api, STATUSES_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchStatuses response data:', data);
        
        if (data.entries) {
          if (options.type === 'closures') {
            this.closures = data.entries;
          } else if (options.type === 'status' && options.featured) {
            this.featuredStatuses = data.entries;
          } else {
            this.statuses = data.entries;
          }
        }
      });
    },
    async fetchClosureDetail(options: any) {
      
        console.log('[Store] fetchClosureDetail called with options:', options);
      
      const api = this.getGqlApi('/api');
      const variables = { id: options.id };
      await executeGqlQuery(api, CLOSURE_QUERY, variables, (payload: any) => {
        
          console.log('[Store] fetchClosureDetail response data:', payload);
        
        if (payload.entry) {
          this.closure = payload.entry;
        }
      });
    },
    async fetchActivities(options: any) {
      
        console.log('[Store] fetchActivities called with options:', options);
      
      const api = this.getGqlApi('/api');
      const variables = { age: options.age };
      await executeGqlQuery(api, ACTIVITIES_QUERY, variables, (data: any) => {
        
          console.log('[Store] fetchActivities response data:', data);
        
        if (data.entries && this.navigation) {
          this.navigation = this.navigation.map((item: any) => ({
            ...item,
            children: item.children?.map((child: any) =>
              child.id === options.id ? { ...child, activities: data.entries } : child
            ),
          }));
          
            console.log('[Store] Navigation updated with activities:', this.navigation);
          
        }
      });
    },
    async fetchEvents(options: any) {
      
        console.log('[Store] fetchEvents called with options:', options);
      
      const EVENTS_ENDPOINT = '/actions/site-module/events/get-events';
      const api = this.getEventsApi(EVENTS_ENDPOINT);
      const variables = {
        ...(this.csrf && { [this.csrf.name]: this.csrf.value }),
        id: options.id,
      };
      this.searchForm = {
        activities: [],
        ages: [],
        locations: options.facility ? [options.facility] : [],
        news: this.searchForm.news,
      };
      await executeEventsQuery(api, variables, (data: any) => {
        
          console.log('[Store] fetchEvents response data:', data);
        
        if (data) {
          this.events = data.events;
        }
      });
    },
    async fetchEventDetail(options: any) {
      
        console.log('[Store] fetchEventDetail called with options:', options);
      
      const api = this.getGqlApi('/api');
      const variables = { id: options.id };
      await executeGqlQuery(api, EVENT_DETAIL, variables, (data: any) => {
        
          console.log('[Store] fetchEventDetail response data:', data);
        
        if (data.entry) {
          this.eventDetail = data.entry;
        }
      });
    },

    // --- Direct setter example ---
    setActiveChild(id: string) {
      
        console.log('[Store] setActiveChild called with id:', id);
      
      this.activeChild = id;
    },

    setClosures(data: object[]) {
      this.closures = data;
    },

    setClosure(data: object) {
      this.closure = data;
    },

    setCsrf(data: CSRF) {
      this.csrf = data;
    },

    setEvents(data: object[]) {
      this.events = data;
    },

    setEventDetail(data: object) {
      this.eventDetail = data;
    },

    setFetchGrandchildren(bool: boolean) {
      // this.fetchGrandchildren = bool;
    },

    setFeaturedData(data: Record<string, unknown>) {
      this.featuredData = data;
    },

    setFilters(data: any) {
      this.filters = data;
    },

    setGqlToken(data: GqlToken) {
      this.gqlToken = data;
    },

    setNavigation(data: NavigationItem[]) {
      this.navigation = data;
    },

    setNews(data: object[]) {
      this.news.all = data;
    },

    setNewsRelated(data: object[]) {
      this.news.related = data;
    },

    setNewsCategories(data: object[]) {
      this.newsCategories = data;
    },

    setPlaceholders(data: object) {
      this.placeholders = data;
    },

    setSearch(data: any) {
      this.searchForm = data;
    },

    setSelectedCategories(data: any) {
      this.selectedCategories = data;
    },

    setSlides(options: { id: string; data: object }) {
      this.slides = {
        [options.id]: options.data,
      };
    },

    setStatuses(data: object[]) {
      this.statuses = data;
    },

    setFeaturedStatuses(data: object[]) {
      this.featuredStatuses = data;
    },
    
    setMenuOpen(value: boolean) {
      this.menuOpen = value;
    },
  },
  persist: {
    key: 'aspen',
  },
});
