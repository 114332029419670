/* app.ts */
import './assets/fonts.js';
import { createApp, computed, onMounted, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import { useMainStore } from './stores/store';
import VueClickAway from 'vue3-click-away';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const main = async () => {
  // Async load Lazysizes
  await import(/* webpackChunkName: "lazysizes" */ 'lazysizes');

  // Create Pinia instance and register the persisted state plugin
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);

  // Create the Vue app using a Composition API–only root component
  const app = createApp({

    setup() {
      const mainStore = useMainStore();

      // Computed properties
      const csrf = computed(() => mainStore.csrf);
      const gqlToken = computed(() => mainStore.gqlToken);
      const placeholders = computed(() => mainStore.placeholders);
      const version = computed(() => mainStore.version);

      // Methods
      const printPage = () => window.print();
      const clearStorage = async () => {
        if (localStorage.getItem('aspen')) {
          if (mainStore.version !== '1.1.1') {
            await localStorage.removeItem('aspen');
          }
        }
      };

      // onMounted lifecycle hook
      onMounted(async () => {
        await clearStorage();
        if (!mainStore.gqlToken) {
          await mainStore.fetchCsrf();
        }
        if (!mainStore.gqlToken) {
          await mainStore.fetchGqlToken();
        }
        console.log("Fetching Hero Slides...");
        await mainStore.fetchHeroSlides({ entryId: 12345 });
        console.log("Hero Slides Fetched:", mainStore.slides);

        await mainStore.fetchPlaceholders();
      });

      return {
        mainStore,
        csrf,
        gqlToken,
        placeholders,
        version,
        printPage,
        clearStorage,
      };
    },
  });

  // Register plugins
  app.use(pinia);
  app.use(VueClickAway);

  // ------------------------------------------------------------------
  // Global Component Registrations
  // ------------------------------------------------------------------

  // Atoms
  app.component('link--classes', defineAsyncComponent(() =>
    import(/* webpackChunkName: "link--classes" */ '../vue/atoms/links/link--classes.vue')
  ));

  // Organisms
  app.component('activities--today', defineAsyncComponent(() =>
    import(/* webpackChunkName: "activities--today" */ '../vue/organisms/activities/activities--today.vue')
  ));
  app.component('calendar--events', defineAsyncComponent(() =>
    import(/* webpackChunkName: "calendar--events" */ '../vue/organisms/calendars/calendar--events.vue')
  ));
  app.component('image--gallery', defineAsyncComponent(() =>
    import(/* webpackChunkName: "image--gallery" */ '../vue/organisms/images/image--gallery.vue')
  ));
  app.component('modal--main', defineAsyncComponent(() =>
    import(/* webpackChunkName: "modal--main" */ '../vue/organisms/modals/modal--main.vue')
  ));
  app.component('navigation--main', defineAsyncComponent(() =>
    import(/* webpackChunkName: "navigation--main" */ '../vue/organisms/navigations/navigation--main.vue')
  ));
  app.component('slider--hero', defineAsyncComponent(() =>
    import(/* webpackChunkName: "slider--hero" */ '../vue/organisms/sliders/slider--hero.vue')
  ));
  app.component('updates--closures', defineAsyncComponent(() =>
    import(/* webpackChunkName: "updates--closures" */ '../vue/organisms/updates/updates--closures.vue')
  ));
  app.component('updates--status', defineAsyncComponent(() =>
    import(/* webpackChunkName: "updates--status" */ '../vue/organisms/updates/updates--status.vue')
  ));
  app.component('cards--news', defineAsyncComponent(() =>
    import(/* webpackChunkName: "cards--news" */ '../vue/organisms/cards/cards--news.vue')
  ));

  app.mount('#page-container');
  return app;
};

main();

if (module.hot) {
  module.hot.accept();
}
