// src/js/stores/getters.js
import { getActiveChild } from "../functions/state";
import moment from 'moment';
import 'moment-timezone';

export const initializeGetters = () => ({

  getActivities: (state) => {
    if (!state.events) return null;
    const activitiesSet = new Set();
    state.events.forEach(event => {
      if (event.type === 'activity' && event.subtitle && event.subtitle.title) {
        const activityObj = {
          id: event.subtitle.id,
          title: event.subtitle.title,
          type: event.type
        };
        activitiesSet.add(JSON.stringify(activityObj));
      }
    });
    const children = Array.from(activitiesSet).map(item => JSON.parse(item));
    return [{
      title: 'All Activities',
      id: 0,
      children
    }];
  },

  getAges: (state) => {
    if (!state.events) return null;
    const agesSet = new Set();
    state.events.forEach(event => {
      if (event.type === 'activity' && event.subtitle && event.subtitle.title && event.ages) {
        event.ages.forEach(age => {
          agesSet.add(JSON.stringify(age));
        });
      }
    });
    const uniqueAges = Array.from(agesSet).map(item => JSON.parse(item));
    return [{
      title: 'All Ages',
      id: 0,
      children: uniqueAges
    }];
  },

  getLocations: (state) => {
    if (!state.events) return null;
    const locationsSet = new Set();
    state.events.forEach(event => {
      if (event.type === 'activity' && event.location && event.location.title) {
        const locationObj = {
          id: event.location.id,
          title: event.location.title,
          type: event.type
        };
        locationsSet.add(JSON.stringify(locationObj));
      }
    });
    const children = Array.from(locationsSet).map(item => JSON.parse(item));
    return [{
      title: 'All Locations',
      id: 0,
      children
    }];
  },

  getChild: (state) => {
    if (state.activeChild && state.navigation) {
      return getActiveChild(state.navigation, state.activeChild);
    }
    return null;
  },

  getClosure: (state) => {
    if (!state.closure) return null;
    const closure = state.closure;
    if (!closure.closureSchedule || !closure.closureSchedule.length) return closure;
    let startClosure, endClosure;
    if (closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime) {
      startClosure = moment(`${closure.closureSchedule[0].closureStartDate} ${closure.closureSchedule[0].closureStartTime}`)
        .tz("America/Denver");
      endClosure = moment(`${closure.closureSchedule[0].closureEndDate} ${closure.closureSchedule[0].closureEndTime}`)
        .tz("America/Denver");
    } else {
      startClosure = moment(closure.closureSchedule[0].closureStartDate).tz("America/Denver");
      endClosure = moment(closure.closureSchedule[0].closureEndDate).tz("America/Denver");
    }
    return {
      id: closure.id,
      title: closure.title,
      schedule: closure.closureSchedule,
      closureStart: startClosure,
      closureEnd: endClosure,
      hasTime: closure.closureSchedule
        ? (closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime ? true : false)
        : null,
      information: closure.closureInformation,
      closed: closure.closeImmediately,
    };
  },

  getClosures: (state) => {
    if (!state.closures) return null;
    const filteredClosures = state.closures
      .map(closure => {
        let startClosure, endClosure, displayClosure;
        let duration = [];
        switch (closure.notification) {
          case 'P1D':
            duration = [1, 'days'];
            break;
          case 'P2D':
            duration = [2, 'days'];
            break;
          case 'P3D':
            duration = [3, 'days'];
            break;
          case 'P1W':
            duration = [1, 'weeks'];
            break;
          case 'P2W':
            duration = [2, 'weeks'];
            break;
          default:
            duration = [0, 'days'];
        }
        if (closure.closureSchedule && closure.closureSchedule.length) {
          if (closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime) {
            startClosure = moment(`${closure.closureSchedule[0].closureStartDate} ${closure.closureSchedule[0].closureStartTime}`)
              .tz("America/Denver");
            endClosure = moment(`${closure.closureSchedule[0].closureEndDate} ${closure.closureSchedule[0].closureEndTime}`)
              .tz("America/Denver");
            displayClosure = moment(`${closure.closureSchedule[0].closureStartDate} ${closure.closureSchedule[0].closureStartTime}`)
              .subtract(duration[0], duration[1])
              .tz("America/Denver");
          } else {
            startClosure = moment(closure.closureSchedule[0].closureStartDate).tz("America/Denver");
            endClosure = moment(closure.closureSchedule[0].closureEndDate).tz("America/Denver");
            displayClosure = moment(closure.closureSchedule[0].closureStartDate)
              .subtract(duration[0], duration[1])
              .tz("America/Denver");
          }
        }
        return {
          id: closure.id,
          title: closure.title,
          schedule: closure.closureSchedule,
          closureStart: startClosure,
          closureEnd: endClosure,
          closureDisplay: displayClosure,
          hasTime: closure.closureSchedule
            ? (closure.closureSchedule[0].closureStartTime && closure.closureSchedule[0].closureEndTime ? true : false)
            : null,
          information: closure.closureInformation,
          closed: closure.closeImmediately,
        };
      })
      .filter(closure => closure.closed || (moment().isAfter(closure.closureDisplay) && moment().isBefore(closure.closureEnd)));
    return filteredClosures.length ? filteredClosures : null;
  },

  getCsrf: (state) => state.csrf,

  getEvents: (state) => {
    if (!state.events) return null;
    let filteredEvents = state.events.map(event => ({
      activityId: event.activityId,
      ages: event.ages,
      canceled: event.canceled,
      classes: event.classes,
      endDate: event.endDate,
      eventId: event.eventId,
      eventType: event.eventType,
      id: event.id,
      instructor: event.instructor,
      location: event.location,
      rawTitle: event.rawTitle,
      startDate: event.startDate,
      subtitle: event.subtitle,
      title: event.title,
      type: event.type,
      ageIds: event.ages ? event.ages.map(cat => cat.id) : null,
    }));
    if (state.searchForm.activities && state.searchForm.activities.length) {
      filteredEvents = filteredEvents.filter(event =>
        state.searchForm.activities.includes(event.activityId)
      );
    }
    if (state.searchForm.ages && state.searchForm.ages.length) {
      filteredEvents = filteredEvents
        .filter(event => event.type !== 'closure' && event.type !== 'meeting' && event.type !== 'event')
        .filter(event => state.searchForm.ages.some(ageId => event.ageIds.includes(ageId)));
    }
    if (state.searchForm.locations && state.searchForm.locations.length) {
      filteredEvents = filteredEvents.filter(event =>
        state.searchForm.locations.includes(event.location.id)
      );
    }
    return (state.searchForm.ages.length || state.searchForm.locations.length || state.searchForm.activities.length)
      ? filteredEvents
      : state.events;
  },

  getDailyEvents: (state) => {
    if (!state.events) return null;
    const dailyTimes = {};
    const eventsForToday = state.events
      .map(event => {
        const eventDate = moment(event.startDate).format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");
        return {
          id: event.eventId,
          title: event.rawTitle,
          canceled: event.canceled,
          abbreviation: event.location.abbreviation,
          date: eventDate,
          today,
          startTime: moment(event.startDate).format("h:mm A"),
          sortTime: new Date(event.startDate.replace(/-/g, '/'))
        };
      })
      .filter(event => event.date === event.today)
      .sort((a, b) => a.sortTime - b.sortTime);

    eventsForToday.forEach(event => {
      dailyTimes[event.id] = dailyTimes[event.id] || [];
      dailyTimes[event.id].push({
        startTime: event.startTime,
        canceled: event.canceled,
      });
    });

    const uniqueDailyEvents = new Set();
    const dailyEvents = eventsForToday.map(event => ({
      id: event.id,
      title: event.title,
      abbreviation: event.abbreviation,
      date: event.date,
      startTimes: dailyTimes[event.id],
    }));
    dailyEvents.forEach(obj => uniqueDailyEvents.add(JSON.stringify(obj)));
    return Array.from(uniqueDailyEvents).map(item => JSON.parse(item));
  },

  getEventDetail: (state) => state.eventDetail,

  getFeaturedData: (state) => state.featuredData,

  getFilters: (state) => state.filters,

  getGqlToken: (state) => state.gqlToken,

  getMenuOpen: (state) => state.menuOpen,

  getNavigation: (state) => state.navigation,

  getNews: (state) => {
    if (!state.news.all) return null;
    const news = state.news.all.map(item => ({
      title: item.title,
      url: item.url,
      featured: item.isFeatured,
      description: item.description,
      newsCategories: item.newsCategory.map(cat => cat.id),
      teaserImage: item.teaserImage,
      otherLinks: item.otherLinks,
    }));
    if (state.selectedCategories.news && state.selectedCategories.news.length) {
      return news.filter(item =>
        state.selectedCategories.news.every(cat => item.newsCategories.includes(cat))
      );
    }
    return news;
  },

  getNewsFeatured: (state) => {
    if (!state.news.all) return null;
    const featuredNews = state.news.all
      .map(item => ({
        title: item.title,
        url: item.url,
        featured: item.isFeatured,
        description: item.description,
        newsCategories: item.newsCategory.map(cat => cat.id),
        teaserImage: item.teaserImage,
        otherLinks: item.otherLinks,
      }))
      .filter(item => item.featured === true);
    if (state.selectedCategories.news && state.selectedCategories.news.length) {
      return featuredNews.filter(item =>
        state.selectedCategories.news.every(cat => item.newsCategories.includes(cat))
      );
    }
    return featuredNews;
  },

  getNewsRelated: (state) => state.news.related || null,

  getNewsCategories: (state) => state.newsCategories,

  getPlaceholders: (state) => state.placeholders || {
    teaserPlaceholder: [],
    sliderPlaceholder: [],
    heroPlaceholder: [],
    articlePlaceholder: [],
    profilePhotoPlaceholder: [],
  },

  getSelectedCategories: (state) => state.selectedCategories,

  getSlides: (state) => state.slides,

  getFeaturedStatuses: (state) => state.featuredStatuses,

  getStatuses: (state) => state.statuses,

  getSearchForm: (state) => state.searchForm,

  getVersion: (state) => state.version,
});
